import React from "react"

import { Container, Row, Col } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { Fade } from "react-reveal"
import Accreditations from "./components/Accreditations"
import Contact from "./components/Contact"

// import { ImageOverlay, MapContainer, TileLayer, useMap } from "react-leaflet";
// import L from "leaflet"

import Skyline from "./img/skyline.jpg"
import Highrise from "./img/highrise.jpg"
import Team from "./img/team.jpg"

// const img = L.ImageOverlay(Skyline)


class Tag extends React.Component {
	render() {
		return(
			<Col xs="12" xl="auto" className="mx-auto my-3 my-xl-auto">
				<Fade top delay={this.props.delay}>
					<Row className="no-gutters">
						<Col xs="auto" className="ml-auto ml-lg-0 my-auto">
							<i className={`fas fa-${this.props.icon} fa-fw fa-4x text-accent`}/>
						</Col>
						<Col className="mr-auto mr-lg-0 pl-3">
							<h4 className="mb-0 font-weight-normal">{this.props.header}</h4>
							<h1 className="mb-0 font-weight-bold">{this.props.text}</h1>
							<h4 className="mb-0 font-weight-normal">{this.props.footer}</h4>
						</Col>
					</Row>
				</Fade>
			</Col>
		)
	}
}

class Info extends React.Component {
	constructor(props) {
		super(props)
		this.toggleView = this.toggleView.bind(this)
		this.state = {
			reveal: false
		}
	}

	toggleView() {
		this.setState({reveal: !this.state.reveal})
	}

	render() {
		return(
			<div className="d-none d-md-block position-absolute">
				<div className="position-relative text-light" style={{ top: `${this.props.y}px`, left: `${this.props.x}px`, transform: "translateY(-50%)" }}>
					<Row className="no-gutters">
						<Col xs="auto" className="my-auto ">
							<i className="fas fa-info-circle fa-2x text-light fa-fw" style={{ zIndex: 9999, cursor: "pointer" }} onMouseEnter={this.toggleView} onMouseLeave={this.toggleView}/>
						</Col>
						<Col>
							<div className={`${!this.state.reveal && "d-none"} my-3 bg-secondary p-3 rounded-lg`}>
								<h4 className="text-dark mb-0">{this.props.title}</h4>
								<p className="text-muted">This is my content</p>
								<div className="bg-dark" style={{width: "250px", height: "250px"}}></div>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		)
	}
}

export default class Home extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			points: [
				{
					title: "Inspections",
					to: "/services#inspections",
					desc: "",
					img: "",
					x: 322,
					y: 255,
					pos: "right"
				},
				{
					title: "Timber Door Installation and Fire Door Maintenance",
					to: "/services#fire-door-maintenance",
					desc: "",
					img: "",
					x: 870,
					y: 155,
					pos: "right"
				},
				{
					title: "Firestopping",
					to: "/services#firestopping",
					desc: "",
					img: "",
					x: 1222,
					y: 78,
					pos: "left"
				},
				{
					title: "Cavity Barriers",
					to: "/services#cavity-barriers",
					desc: "",
					img: "",
					x: 1442,
					y: 286,
					pos: "left"
				}
			]
		}
	}

	render() {
		return (
			<>
				<Helmet>
					<title>Home • Fireless Ltd</title>
					<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.2/leaflet.css" integrity="sha512-UkezATkM8unVC0R/Z9Kmq4gorjNoFwLMAWR/1yZpINW08I79jEKx/c8NlLSvvimcu7SL8pgeOnynxfRpe+5QpA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
				</Helmet>
				<div style={{backgroundImage: `url(${Skyline})`, backgroundPosition: "center", height: "550px"}} className="w-100">
					{/* { this.state.points.map((v) => {
						return(
							<Info {...v}/>
						)
					})} */}
				</div>
				<div className="bg-secondary text-dark py-5">
					<Container>
						<Row className="no-gutters">
							<Tag icon="calendar" header="Over" text="10 Years" footer="Industry Experience" delay={0}/>
							<Tag icon="award" text="Accredited" footer="ASFP, CHAS, FIRAS, ISO 9001, SMAS, CITB" delay={100}/>
							<Tag icon="check-square" header="Getting it" text="Right" footer="The First Time" delay={200}/>
						</Row>
					</Container>
				</div>
				<Container className="my-5 text-center">
					<h1 className="mb-0">Protecting and preserving life and property by the installation of approved fire stopping systems.</h1>
					<p>Fireless Ltd</p>
					{/* <h1 className="my-5">Meet The Team</h1> */}
					<img src={Team} className="w-50 text-center mb-2 rounded-lg"/>
				</Container>
				<Accreditations/>
				<Contact/>
			</>
		)
	}
}