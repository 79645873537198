import React from "react"
import { Link, NavLink, Outlet } from "react-router-dom"
import { Link as ScrollLink } from "react-scroll"

import { Container, Row, Col } from "react-bootstrap"

import Logo from "./img/logo.png"

class Tab extends React.Component {
    render() {
        return(
            <Col xs="auto" className="mx-auto my-auto pl-3">
                <h4 className="mb-0 font-weight-light"><NavLink end className={({ isActive }) => isActive ? `font-weight-normal text-light text-decoration-none` : `font-weight-lightest text-light text-decoration-none`} to={this.props.to}>{this.props.name}</NavLink></h4>
            </Col>
        )
    }
}

export default class Layout extends React.Component {
	render() {
		return (
            <div className="text-light">
                <Container>
                    <div className="py-4">
                        <Row className="no-gutters">
                            <Col xs="12" lg="5" className="mx-auto">
                                <Row className="no-gutters">
                                    <Col xs="3" sm="2" className="ml-auto ml-lg-0">
                                        <img src={Logo} className="w-100"/>
                                    </Col>
                                    <Col xs="auto" lg="10" className="my-auto mr-auto mr-lg-0">
                                        <h1 className="mb-1 font-weight-normal"><Link to="/" className="text-decoration-none text-light">Fireless Ltd</Link></h1>
                                        <h4 className="mb-0 font-weight-lightest">Protecting life and property</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="auto" className="my-3 my-lg-auto mx-auto mr-lg-0 ml-sm-auto">
                                <Row className="no-gutters">
                                    <Col className="d-none d-sm-block"/>
                                    <Tab name="Home" to="/"/>
                                    <Tab name="About" to="/about"/>
                                    <Tab name="Services" to="/services"/>
                                    <Col xs="auto" className="mx-auto mt-3 mt-sm-0 pl-sm-3">
                                        <ScrollLink href="#contact-us" to="contact-us" smooth={true} spy={true} duration={500} className="text-light text-decoration-none">
                                            <h4 className="mb-0 px-4 py-3 rounded-lg font-weight-bold text-light bg-accent">Contact Us</h4>
                                        </ScrollLink>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="auto" className="text-center ml-auto">
                                <h5 className="font-weight-normal"><a href="tel:+441625402727" className="text-light text-decoration-none"><i className="fas fa-phone-alt mr-2"/>+44 1625 402727</a></h5>
                            </Col>
                            <Col xs="12" sm="auto" className="text-center mr-auto mr-lg-0">
                                <h5 className="font-weight-normal"><a href="mailto:sales@firelessltd.com" className="text-light text-decoration-none"><i className="fas fa-envelope mr-2"/>sales@firelessltd.com</a></h5>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Outlet/>
                <Container className="pb-3">
                    <hr className="border-light"/>
                    <Row>
                        <Col xs="12" md="6">
                            {/* <Link className="h1 text-light text-decoration-none font-weight-light" to="/">Fireless Ltd</Link>
                            <p>Protecting life and property</p> */}
                            <h1 className="mb-1 font-weight-normal"><Link to="/" className="text-decoration-none text-light">Fireless Ltd</Link></h1>
                            <h4 className="mb-0 font-weight-lightest">Protecting life and property</h4>
                        </Col>
                        <Col xs="12" md="6" className="my-auto">
                            {/* <Row>
                                <Col xs="auto" className="ml-auto">
                                    <a href="https://www.linkedin.com/in/fireless-ltd-75159b113/" className="text-light" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in fa-2x"/></a>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col xs="12" sm="auto" className="text-center ml-auto">
                                    <h5 className="font-weight-normal"><a href="tel:+441625402727" className="text-light text-decoration-none"><i className="fas fa-phone-alt mr-2"/>+44 1625 402727</a></h5>
                                </Col>
                                <Col xs="12" sm="auto" className="text-center mr-auto mr-lg-0">
                                    <h5 className="font-weight-normal"><a href="mailto:sales@firelessltd.com" className="text-light text-decoration-none"><i className="fas fa-envelope mr-2"/>sales@firelessltd.com</a></h5>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className="mt-3 text-center">
                        <h5>© 2022 FIRELESS LTD</h5>
                        <p>Registered in England and Wales. Company number 09761109.</p>
                    </div>
                </Container>
            </div>
		)
	}
}