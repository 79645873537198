import React from "react"
import { Link } from "react-scroll"

export default class CallToAction extends React.Component {
	render() {
		return(
			<Link href="#contact-us" to="contact-us" smooth={true} spy={true} duration={500} className="text-light text-decoration-none">
			    <h4 className="text-right font-weight-normal">Get in Contact <i className="fas fa-chevron-right"/></h4>
            </Link>
		)
	}
}