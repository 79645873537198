import React from "react"

import { Container, Row, Col, Modal } from "react-bootstrap"
import { Helmet } from "react-helmet"
import Accreditations from "./components/Accreditations"
import CallToAction from "./components/CallToAction"
import Contact from "./components/Contact"

import Highrise from "./img/highrise.jpg"
import School from "./img/school.jpg"
import Lifted from "./img/lifted.jpg"

class Testimonial extends React.Component {
	render() {
		return(
			<>
				<Row className="my-5">
					{/* <Col xs="2" className="my-auto">
						<img src={GeoffMarland} className="rounded-circle w-100"/>
					</Col> */}
					<Col className="my-auto">
						<hr className="my-2 border-light"/>
						<h2 className="mb-3 font-weight-normal"><i>`We needed certified contractors we could rely on to provide cost effective solutions and carry out essential fire stopping work to our properties - Fireless Ltd met all our needs, they were easy to work with, flexible & reliable.`</i></h2>
						<p><span className="h4 mb-0">- Geoff Marland BSc (Hon) MCIOB</span><br/>Investment Operational Manager<br/>Environmental & Building Surveyor<br/><span className="h5 mb-0">Jigsaw Homes Group</span></p>
						<hr className="my-2 border-light"/>
					</Col>
				</Row>
				<Row className="my-5">
					{/* <Col xs="2" className="my-auto">
						<img src={GeoffMarland} className="rounded-circle w-100"/>
					</Col> */}
					<Col className="my-auto">
						<hr className="my-2 border-light"/>
						<h2 className="mb-3 font-weight-normal"><i>`We have used Fireless Ltd on a number of our developments, they have brought expertise and reliability for all our passive fire protection requirements, their assistance has proved invaluable in delivering on our units as a house builder in accordance with the regulatory requirements`</i></h2>
						<p><span className="h4 mb-0">- Neil Bradley</span><br/>Commercial Director<br/><span className="h5 mb-0">Barratt & David Wilson Homes North West</span></p>
						<hr className="my-2 border-light"/>
					</Col>
				</Row>
				<Row className="my-5">
					{/* <Col xs="2" className="my-auto">
						<img src={GeoffMarland} className="rounded-circle w-100"/>
					</Col> */}
					<Col className="my-auto">
						<hr className="my-2 border-light"/>
						<h2 className="mb-3 font-weight-normal"><i>`Thanks for the service that we have received from yourselves, completing all the fire stopping requirements to my apartment blocks. Everything was done in a very thorough and professional manner, monitored by Carlos, who was a pleasure to deal with. On inspection, the NHBC inspector, was impressed by the level of quality achieved.`</i></h2>
						<p><span className="h4 mb-0">- Andy Rigby</span><br/>Senior Site Manager<br/><span className="h5 mb-0">Taylor Wimpey</span></p>
						<hr className="my-2 border-light"/>
					</Col>
				</Row>


				
			</>
		)
	}
}

export default class About extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showQualityPolicy: false
		}
	}

	render() {
		return (
			<>
				<Helmet>
					<title>About • Fireless Ltd</title>
				</Helmet>
				<Modal close centered show={this.state.showQualityPolicy} onHide={() => {this.setState({showQualityPolicy: false})}}>
					<Modal.Header className="text-right">
						<Row className="no-gutters w-100">
							<Col/>
							<Col xs="auto">
								<i className="mb-0 fas fa-times" style={{ cursor: "pointer" }} onClick={() => {this.setState({showQualityPolicy: false})}}/>
							</Col>
						</Row>
					</Modal.Header>
					<Modal.Body>
						<h1 className="text-center">Quality Policy</h1>
						<p>It is the policy of Fireless Ltd to maintain a quality system designed to meet the requirements of ISO9001:2015 (or any other standard in line with Annex SL Structure) in pursuit of its primary objectives, the purpose and the context of the organisation.<br/><br/>It is the policy of Fireless Ltd to:</p>
						<ul>
							<li>strive to satisfy the requirements of all of our customers, stakeholders and interested parties whenever possible, meeting and exceeding their expectations;</li>
							<li>comply with all legal requirements, codes of practice and all other requirements applicable to our activities;</li>
							<li>the reduction of hazards, prevention of injury, ill health and pollution;</li>
							<li>provide all the resources of equipment, trained and competent staff and any other requirements to enable these objectives to be met;</li>
							<li>ensure that all employees are made aware of their individual obligations in respect of this quality policy;</li>
							<li>maintain a management system that will achieve these objectives and seek continual improvement in the effectiveness and performance of our management system based on “risk”.</li>
    
						</ul>
						<p>
							This quality policy provides a framework for setting, monitoring, reviewing and achieving our objectives, programmes and targets.<br/><br/>
							Customer service is an essential part of the quality process and to ensure this is fulfilled, all employees receive training to ensure awareness and understanding of quality and its impact on customer service.<br/><br/>
							To ensure the company maintains its awareness for continuous improvement, the quality system is regularly reviewed by “Top Management” to ensure it remains appropriate and suitable to our business. The Quality System is subject to both internal and external annual audits.<br/><br/>
							Signed by:<br/>
							C. Mckoy and R. Poorhady 3rd September 2024
						</p>
					</Modal.Body>
				</Modal>
				<div className="py-5 bg-secondary text-center text-dark">
					<h1 className="display-4 font-weight-bold">About Us</h1>
				</div>
				<Container className="my-5">
					<Row>
						<Col className="my-auto">
							<p>Based in the North West, Fireless Ltd have completed and certified over 400 firestopping projects throughout England.</p>
							<p>Aiming to Protect and preserve life and property, we install passive fire protection systems; preventing the spread of flame, heat and smoke.</p>
							<p>These principles maintain the fundamental requirements of building compartmentation, structural stability and fire separation which provides a safe means of escape.</p>
							<p>Our experience, integrity and work ethic make us one of the leading competitors for your fire protection requirements.</p>
							<p>We are a FIRAS Certified contractor, with a vast experience installing high quality fire stop systems to voids and service penetrations.</p>
							<CallToAction/>
						</Col>
						<Col xs="4" className="my-auto">
							<img src={Lifted} className="w-100 rounded-lg"/>
						</Col>
					</Row>
				</Container>
				<Accreditations/>
				<Container className="my-5">
					<Row>
						<Col xs="4" className="my-auto">
							<img src={School} className="w-100 rounded-lg"/>
						</Col>
						<Col className="my-auto">
							<p className="mb-0">Fireless Ltd specialises in, and caters for, various sectors, including:</p>
							<ul className="mb-0 list-unstyled">
								<li>
									<h1 className="mb-0 font-weight-light">Healthcare</h1>
								</li>
								<li>
									<h1 className="mb-0 font-weight-light">Education</h1>
								</li>
								<li>
									<h1 className="mb-0 font-weight-light">Social Housing</h1>
								</li>
								<li>
									<h1 className="mb-0 font-weight-light">Residential</h1>
								</li>
								<li>
									<h1 className="mb-0 font-weight-light">Private Residential</h1>
								</li>
								<li>
									<h1 className="mb-0 font-weight-light">Commercial</h1>
								</li>
								<li>
									<h1 className="mb-0 font-weight-light">New Build</h1>
								</li>
								<li>
									<h1 className="mb-0 font-weight-light">Hotel</h1>
								</li>
								<li>
									<h1 className="mb-0 font-weight-light">Leisure</h1>
								</li>
							</ul>
							<p className="mb-0">Fireless Ltd also take pride in completing remedial and maintenance work.</p>
							<CallToAction/>
						</Col>
					</Row>
				</Container>
				<div className="bg-secondary">
					<Container className="py-5">
						<Row className="no-gutters">
							<Col xs="auto" className="m-auto">
								<Row>
									<Col xs="auto">
										<i className="text-accent fas fa-door-open fa-4x"/>
									</Col>
									<Col className="my-auto">
										<h1 className="text-dark">Safer Escape</h1>
									</Col>
								</Row>
							</Col>
							<Col xs="auto" className="m-auto">
								<Row>
									<Col xs="auto">
										<i className="text-accent fas fa-building fa-4x"/>
									</Col>
									<Col className="my-auto">
										<h1 className="text-dark">Safer Buildings</h1>
									</Col>
								</Row>
							</Col>
							<Col xs="auto" className="m-auto">
								<Row>
									<Col xs="auto">
										<i className="text-accent fas fa-hard-hat fa-4x"/>
									</Col>
									<Col className="my-auto">
										<h1 className="text-dark">Safer Fire Service</h1>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
				<Container>
					<Testimonial/>
					{/* <Testimonial/>
					<Testimonial/> */}
				</Container>
				<div className="bg-secondary text-center">
					<h1 className="my-5 p-4 rounded-lg text-center bg-dark d-inline-block" style={{ cursor: "pointer" }} onClick={() => {this.setState({showQualityPolicy: true})}}><i className="fas fa-file"/> Quality Policy</h1>
				</div>
				<Contact/>
			</>
		)
	}
}
