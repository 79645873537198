import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Layout from "./Layout"
import Home from "./Home"
import About from "./About"
import Services from "./Services"

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<BrowserRouter>
		<Routes>
			<Route element={<Layout/>}>
				<Route index exact path="/" element={<Home/>}/>
				<Route path="/about" element={<About/>}/>
				<Route path="/services" element={<Services/>}/>
			</Route>
		</Routes>
	</BrowserRouter>
)