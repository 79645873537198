import React from "react"

import { Container, Row, Col } from "react-bootstrap"
import { Helmet } from "react-helmet"
import CallToAction from "./components/CallToAction"
import Contact from "./components/Contact"

import Firestopping from "./img/firestopping.jpg"
import CavityBarriers from "./img/cavity_barriers.jpg"
import Highrise from "./img/highrise.jpg"
import Inspections from "./img/inspections.png"
import FireDoor from "./img/firedoor.jpg"
import { Fade } from "react-reveal"

export default class Services extends React.Component {
	render() {
		return (
			<>
				<Helmet>
					<title>Services • Fireless Ltd</title>
				</Helmet>
				<div className="py-5 bg-secondary text-center text-dark">
					<h1 className="display-4 font-weight-bold">Services</h1>
					{/* <p>We have a lot to share here at Fireless Ltd, here you can get to know us better!</p> */}
				</div>
				<div className="overflow-hidden">
					<Container className="my-5 ">
						<Row>
							<Col xs="4">
								<Fade left>
									<img src={Firestopping} className="w-100 rounded-lg"/>
								</Fade>
							</Col>
							<Col xs="8" className="my-auto">
								<Fade right>
									<div>
										<h1>Firestopping</h1>
										<p className="font-weight-light">Fireless Ltd ensure that the approved fire stop systems are used at the junction of compartment walls, floors and ceilings to maintain the integrity of the fire rated construction. We install fire stop systems to voids and service penetrations to stop the spread of smoke and fire from one compartment to another.</p>
										<p className="font-weight-lightest">All systems installed are specified from the manufacture as such, and have the testing certificate for the fire stop solution installed. Fireless Ltd ensures that all firestop materials are UL Certified.</p>
										<p className="font-weight-lightest">We work closely with Fire Risk Assessments, the Building Control Approved Document B (Fire Safety) Volume 1 & 2 and The Regulatory Reform Act (Fire Safety) Order 2005 and ensure all actions required within the protection from fire/smoke spread are being adhered to.</p>
										<CallToAction/>
									</div>
								</Fade>
							</Col>
						</Row>
						<hr className="my-5 border-light"/>
						<Row>
							<Col xs="4">
								<Fade left>
									<img src={CavityBarriers} className="w-100 rounded-lg"/>
								</Fade>
							</Col>
							<Col xs="8" className="my-auto">
								<Fade right>
									<div>
										<h1>Cavity Barriers</h1>
										<p className="font-weight-light">Fireless Ltd install cavity barriers to sub-divide concealed voids in the fire rated construction, this prevents the spread of fire and smoke between voids, this service involves cavity barrier installation between external cavity wall and every compartment floor and wall. It is also integral for the cavity barrier installation to internal cavity wall and every compartment wall or door assembly that forms part of the fire resistant barrier.</p>
										<p className="font-weight-lightest">All systems installed are specified from the manufacture as such, and have the testing certificate for the fire stop solution installed. Fireless Ltd ensures that all firestop materials are UL Certified.</p>
										<p className="font-weight-lightest">We work closely with Fire Risk Assessments, the Building Control Approved Document B (Fire Safety) Volume 1 & 2 and The Regulatory Reform Act (Fire Safety) Order 2005 and ensure all actions required within the protection from fire/smoke spread are being adhered to.</p>
										<CallToAction/>
									</div>
								</Fade>
							</Col>
						</Row>
						<hr className="my-5 border-light"/>
						<Row>
							<Col xs="8" className="my-auto">
								<Fade left>
									<div>
										<h1>Inspections</h1>
										<p>Fireless Ltd are comprehensive and detailed in our inspections of both commercial and residential buildings, ensuring our clients comply with the latest fire safety regulations. We act as a third party and will always remain impartial in our inspections and recommendations.</p>
										<p>All fire stopping surveys, installations, repairs and inspections carried out by Fireless Ltd are recorded using Bolster Systems, providing our clients with an easy and effective way to monitor fire stopping projects.</p>
										<CallToAction/>
									</div>
								</Fade>
							</Col>
							<Col xs="4">
								<Fade right>
									<img src={Inspections} className="w-100 rounded-lg"/>
								</Fade>
							</Col>
						</Row>
						<hr className="my-5 border-light"/>
						<Row>
							<Col xs="8" className="my-auto">
								<Fade left>
									<div>
										<h1>Timber Door Installation and Fire Door Maintenance</h1>
										<p>Fireless Ltd can install Timber Doors and can provide regular maintenance and periodical inspections.</p>
										<p>Effective fire doors are integral to any passive fire protection and compartmentation strategy in both commercial and residential buildings. They offer protection for occupants against the spread of smoke and fire and preserve crucial escape routes.</p>
										<p>Fire doors are a critical component of a fire protection strategy, which reinforces the need for regular maintenance and periodical inspections (at least once every six months) to ensure that their condition adheres to all current legal requirements for fire safety legislation.</p>
										<CallToAction/>
									</div>
								</Fade>
							</Col>
							<Col xs="4">
								<Fade right>
									<img src={FireDoor} className="w-100 rounded-lg"/>
								</Fade>
							</Col>
						</Row>
						<hr className="my-5 border-light"/>
					</Container>
				</div>
				<Contact/>
			</>
		)
	}
}